import React from "react";

import { Formik, Form } from "formik";
import * as yup from "yup";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { postService } from "../../utils/ServiceUtils";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { InputForPhone } from "./InputForPhone";

export const StyledLabel = styled(Typography)(() => ({
  marginTop: "15px",
  marginBottom: "5px",
  fontSize: "0.75rem",
  fontWeight: 700,
  color: "#606567",
}));

const BODY_TEXT_HTML = {
    __html: `Please review the latest patient information on file before resending the invite. Fill out Cell Phone <b>OR</b> Email Field to resend invite.`,
  };
  const PHONE_LABEL = "Cell Phone";
  const EMAIL_LABEL = "Email";
const TITLE_TEXT = "Resend Invite";
const phoneRegExp = /^\+?[1-9]\d{1,14}$/i;
const emailValidation = yup.string().email("Email is not valid");
export const phoneValidation = yup
  .string()
  .matches(phoneRegExp, "Phone number is not valid")
  .test("is-possible-phone-number", "Phone number is not valid", (val) => {
    return !!val ? isPossiblePhoneNumber(val) : true;
  });
export const waitingRoomResendMeetingLinkSchema = yup
  .object()
  .shape({
    email: emailValidation,
    cellPhone: phoneValidation,
  })
  .test(
    "at-least-one-field",
    "Either a valid Cell Phone or Email is required",
    (values) => {
      const isValidPhone =
        !!values?.cellPhone && phoneRegExp.test(values?.cellPhone) && isPossiblePhoneNumber(values?.cellPhone);
      const isValidEmail = !!values?.email && yup.string().email().isValidSync(values?.email);

      return isValidPhone || isValidEmail; 
    }
  );
export const ResendInviteModalContainer = ({
  open,
  onClose,
  meetingContext,
  setInfoMessage,
  deviceStatusData
}) => {
 
  const resendInvite = async (data) => {
   
    await postService("/api/monitoring-device/tytocare/ordered", {
      chatId: meetingContext?.chat?.id,
      phoneNumber: data?.cellPhone,
      emailAddress: data?.email,
     });
    onClose();
    setInfoMessage({
      message: "Pair device outreach sent",
      color: "green",
    });
    setTimeout(() => setInfoMessage(""), 5000);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth  PaperProps={{
        sx: {
          margin: "2px",
          width:"100%",
          height:"100%",
          maxHeight:"100%"
        },
      }}>
      <DialogTitle sx={{fontSize:"16px"}}>{TITLE_TEXT}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <Divider sx={{ mx: 3 }} />
      <DialogContent>
      <Box>
      <Typography sx={{px:2, fontSize:"14px"}} dangerouslySetInnerHTML={BODY_TEXT_HTML} />
      <Formik
        initialValues={{ cellPhone: deviceStatusData?.patientPhoneNumber ?? "", email: deviceStatusData?.patientEmailAddress ?? "" }}
        onSubmit={async (data, { setSubmitting }) => {
          await resendInvite({
            ...data,
          });
          setSubmitting(false);
        }}
        validationSchema={waitingRoomResendMeetingLinkSchema}
        validateOnMount
        initialErrors={{email:"Either a valid Cell Phone or Email is required"}}
        enableReinitialize
      >
        {({
          values,
          errors,
          setFieldValue,
          isValid,
          isSubmitting,
          handleChange,
        }) => {
          return (
            <Form>
              <Stack
                sx={{
                  justifyContent: "space-between",
                  width: "100%",
                  paddingX: 1,
                }}
              >
                <Stack
                  sx={{
                    width: "100%",
                  }}
                >
                  <StyledLabel>{PHONE_LABEL}</StyledLabel>
                  <InputForPhone
                    name="cellPhone"
                    value={values?.cellPhone}
                    error={!!errors?.cellPhone}
                    className="h-1"
                    setValue={(value) => {
                      setFieldValue("cellPhone", value);
                    }}
                    shadow={false}
                  />
                </Stack>

                <Stack
                  sx={{
                    width: "100%",
                  }}
                >
                  <StyledLabel>{EMAIL_LABEL}</StyledLabel>
                  <TextField
                    name="email"
                    value={values?.email}
                    error={!!errors?.email}
                    fullWidth
                    onChange={handleChange}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                          boxShadow: "0 0 0 3px rgba(66, 153, 225, 0.5)",
                        },
                      },
                      "& .MuiInputBase-input": {
                        height: "35px",
                        padding: " 1px 14px",
                      },
                    }}
                  />
                </Stack>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  justifyContent: "flex-end",
                  mt: "2.5rem",
                }}
              >
                <Button
                  variant="contained"
                  sx={{color:"#ffffff" ,bgcolor:"#74797B"}}
                  type="button"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  sx={{color:"#ffffff",bgcolor:"#3BB8C1"}}
                  type="submit"
                  loading={isSubmitting}
                  disabled={!isValid || !(values.cellPhone||values.email) || isSubmitting}
                >
                  Resend
                </Button>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </Box>
      </DialogContent>
    </Dialog>
  );
};

