import "./sidePan.css";

import * as microsoftTeams from "@microsoft/teams-js";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { ButtonsCard } from "./ButtonsCard";
import { getService, postService } from "../../utils/ServiceUtils";
import Card from "@mui/material/Card";
import { GenericMessageCard } from "./GenericMessageCard";
import { UserContext } from "../App";
import { UserLaunchedTyto } from "./UserLaunchedTyto";
import useQuery from "../../hooks/useQuery";
import { ResendInviteModalContainer } from "./resendInviteModal";

export default function OutlinedCard() {
  const meetingContext = React.useContext(UserContext);
  const [isDevicePaired, setIsDevicePaired] = useState(false);
  const [isDeviceOnline, setIsDeviceOnline] = useState(false);
  const [deviceStatusData, setDeviceStatusData] = useState(false);
  const [infoMessage, setInfoMessage] = useState();
  const [openInviteModal, setOpenInviteModal] = useState(false);

  const [buttonLoaders, setButtonLoaders] = useState({
    isPairing: false,
    isRefreshing: false,
    isDeviceLaunching: false,
    isReviewUrlLaunching: false,
    isBroswerLaunching: false,
  });

  // let urlString = new URLSearchParams(window.location.href);

  // const clinicianUrldecoded = urlString.get("clinicianUrl");

  const currentUser = meetingContext?.user?.loginHint;
  const launchedBy = useQuery().get("launchedBy");
  const clinicianUrl = useQuery().get("clinicianUrl");

  useEffect(() => {
    !!meetingContext?.chat?.id && getDeviceStatusData();
    // eslint-disable-next-line
  }, [meetingContext]);
  const fetchMeetingContext = async () => {
    const tytoDetails = await postService(
      "/api/monitoring-device/tytocare/visit",
      {
        chatId: meetingContext?.chat?.id,
        serviceId: 2,
      }
    );

    return tytoDetails;
  };
  const getDeviceStatusData = async () => {
    //sample chatId for this api with valid data
    // https://dev-apim.andor.app/api/monitoring-device/tytocare/get?chatId=19%3Ameeting_OTU2YzVhZDItMDE2Zi00YzlhLWEyYjUtZjRlYmQ5OGNmNmJh%40thread.v2&serviceId=2
    const deviceStatus = await getService(
      `/api/monitoring-device/tytocare/get?chatId=${meetingContext?.chat?.id}&serviceId=2`
    );
    if (deviceStatus?.isSuccess) {
      setIsDeviceOnline(deviceStatus?.data?.isDeviceOnline);
      setIsDevicePaired(deviceStatus?.data?.devicePairingStatus === "PAIRED");
      setDeviceStatusData(deviceStatus?.data)
    } else {
      setInfoMessage({
        message: "There was an error refreshing your statuses.",
        color: "red",
      });
      setTimeout(() => setInfoMessage(""), 5000);
    }
  };
  const getStagingUrl = async () => {
    await setButtonLoaders({ ...buttonLoaders, isDeviceLaunching: true });
    await fetchMeetingContext().then(async (tytoDetails) => {
      if (tytoDetails?.isSuccess) {
        await microsoftTeams.meeting.getAppContentStageSharingState(
          async (err, result) => {
            if (!result?.isAppSharing) {
              if (!!tytoDetails?.data?.clinicianUrl) {
                tytoDetails?.data?.status !== "ACTIVE" &&
                  (await microsoftTeams.meeting.shareAppContentToStage(
                    (err, result) => {
                      if (result) {
                      }
                      if (err) {
                        alert("There was an error launcing device");
                      }
                    },
                    `${window.baseUrl}/#/tab?launchedBy=${meetingContext?.user.loginHint}&clinicianUrl=` +
                      encodeURIComponent(tytoDetails?.data?.clinicianUrl)
                  ));
              }
            } else {
              setInfoMessage({
                message: `Another user has already launched tyto device to meeting stage`,
                color: "red",
              });
              setTimeout(() => setInfoMessage(""), 5000);
            }
          }
        );
      } else {
        setInfoMessage({
          message: "There was an error getting your tyto feed",
          color: "red",
        });
        setTimeout(() => setInfoMessage(""), 5000);
      }
    });

    await setButtonLoaders({
      ...buttonLoaders,
      isDeviceLaunching: false,
    });
  };
  const browserLaunch = async () => {
    await setButtonLoaders({ ...buttonLoaders, isBroswerLaunching: true });
    await fetchMeetingContext().then(async (tytoDetails) => {
      if (tytoDetails?.isSuccess) {
        if (!!tytoDetails?.data?.clinicianUrl) {
          tytoDetails?.data?.status !== "ACTIVE" &&
            window.open(tytoDetails?.data?.clinicianUrl, "_blank");
        }
      } else {
        setInfoMessage({
          message: "There was an error getting your tyto feed",
          color: "red",
        });
        setTimeout(() => setInfoMessage(""), 5000);
      }
    });
    await setButtonLoaders({
      ...buttonLoaders,
      isBroswerLaunching: false,
    });
  };

  const getDummyStagingUrl = async () => {
    await setButtonLoaders({ ...buttonLoaders, isDeviceLaunching: true });

    await microsoftTeams.meeting.getAppContentStageSharingState(
      (err, result) => {
        if (!result?.isAppSharing) {
          microsoftTeams.meeting.shareAppContentToStage((err, result) => {
            if (result) {
            }
            if (err) {
              console.log("err",err)
              // handle error
            }
          }, `${window.baseUrl}/#/tab?launchedBy=${meetingContext?.user.loginHint}&clinicianUrl=` + encodeURIComponent("https://stage1.tytocare.com/clinician-station/dist/index.html#/entryEmbedded/n5v7RO1D/rbw1iIySYf2wEnIxYnKcxONMeXufp3dNKOxPMHDafd0*"));
        } else {
          setInfoMessage({
            message: `Another user has already launched tyto device to meeting stage`,
            color: "red",
          });
          setTimeout(() => setInfoMessage(""), 5000);
        }
      }
    );

    await setButtonLoaders({
      ...buttonLoaders,
      isDeviceLaunching: false,
    });
  };
  const handlePairDevice = async () => {
    await setButtonLoaders({ ...buttonLoaders, isPairing: true });
    setOpenInviteModal(true);
    
    await setButtonLoaders({
      ...buttonLoaders,
      isPairing: false,
    });
    
  };

  const launchReviewUrl = async () => {
    await setButtonLoaders({ ...buttonLoaders, isReviewUrlLaunching: true });

    await fetchMeetingContext().then(async (tytoDetails) => {
      if (tytoDetails?.isSuccess) {
        !!tytoDetails?.data?.reviewerUrl &&
          window.open(tytoDetails?.data?.reviewerUrl, "_blank");
      } else {
        setInfoMessage({
          message: "There was an error getting your tyto feed",
          color: "red",
        });
        setTimeout(() => setInfoMessage(""), 5000);
      }
    });
    await setButtonLoaders({ ...buttonLoaders, isReviewUrlLaunching: false });
  };
  const handleRefresh = async () => {
    await setButtonLoaders({
      ...buttonLoaders,
      isRefreshing: true,
    });
    await getDeviceStatusData();
    await setButtonLoaders({
      ...buttonLoaders,
      isRefreshing: false,
    });
  };

  return (
    <div id="start">
      <Box>
        {meetingContext?.page?.frameContext === "sidePanel" ? (
          <div className="card">
            <Card>
              <ButtonsCard
                isDevicePaired={isDevicePaired}
                isDeviceOnline={isDeviceOnline}
                getDummyStagingUrl={getDummyStagingUrl}
                getStagingUrl={getStagingUrl}
                buttonLoaders={buttonLoaders}
                browserLaunch={browserLaunch}
                launchReviewUrl={launchReviewUrl}
                handlePairDevice={handlePairDevice}
                handleRefresh={handleRefresh}
                infoMessage={infoMessage}
              />
            </Card>
          </div>
        ) : meetingContext?.page?.frameContext === "meetingStage" ? (
          !!clinicianUrl && currentUser === launchedBy ? (
            <iframe
              title="Discharge Summary"
              style={{ height: "100%", width: "100%" }}
              src={clinicianUrl}
            ></iframe>
          ) : !!clinicianUrl && currentUser !== launchedBy ? (
            <>
              <UserLaunchedTyto launchedBy={launchedBy} />
            </>
          ) : (
            <div className="card">
              <Card>
                <ButtonsCard
                  isDevicePaired={isDevicePaired}
                  isDeviceOnline={isDeviceOnline}
                  getDummyStagingUrl={getDummyStagingUrl}
                  getStagingUrl={getStagingUrl}
                  buttonLoaders={buttonLoaders}
                  browserLaunch={browserLaunch}
                  launchReviewUrl={launchReviewUrl}
                  handlePairDevice={handlePairDevice}
                  handleRefresh={handleRefresh}
                  infoMessage={infoMessage}
                />
              </Card>
            </div>
          )
        ) : (
          <>
            <GenericMessageCard />
          </>
        )}
        {}
      </Box>
      <ResendInviteModalContainer open={openInviteModal} onClose={()=>setOpenInviteModal(false)} meetingContext={meetingContext} setInfoMessage={setInfoMessage} deviceStatusData={deviceStatusData}/>
    </div>
  );
}
