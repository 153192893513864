import "./App.css";

import * as microsoftTeams from "@microsoft/teams-js";

import { ThemeProvider, createTheme, CssBaseline } from "@mui/material";
import React, { createContext, useEffect, useState } from "react";
import { Navigate, Route,Routes, HashRouter as Router } from "react-router-dom";

import Overview from "./Overview";
import Privacy from "./Privacy";
import Tab from "./Tab";
import TabConfig from "./TabConfig";
import TermsOfUse from "./TermsOfUse";
import forEach from "lodash/forEach";
import { getFile } from "../utils/ServiceUtils";


const teamsMuiTheme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: "#eeeeee",
    },
    primary: {
      main: "#6264a7", 
    },
  },
  typography: {
    fontFamily: "Segoe UI, Roboto, sans-serif",
  },
});
export const UserContext = createContext();
export default function App() {
  const [meetingContext, setMeetingContext] = useState();

  const performInit = async () => {
    await microsoftTeams.app.initialize();

    const context = await microsoftTeams.app.getContext();
    setMeetingContext(context);
  };

  useEffect(() => {
    performInit();
  }, []);

  return (
    <UserContext.Provider value={meetingContext}>
       <ThemeProvider theme={ teamsMuiTheme }>
       <CssBaseline />
        <SetUpAppReady>
          <Router>
            <Routes>
              <Route path="/" element={<Navigate to="/tab" />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/termsofuse" element={<TermsOfUse />} />
              <Route path="/tab" element={<Tab />} />
              <Route path="/config" element={<TabConfig />} />
              <Route path="/overview" element={<Overview />} />
            </Routes>
          </Router>
        </SetUpAppReady>
      </ThemeProvider>
    </UserContext.Provider>
  );
}
const SetUpAppReady = ({ children }) => {
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    const setConfigs = async () => {
      const configJSON = await getFile("/config.json");
      forEach(configJSON, (value, name) => {
        window[name] = value;
      });
      setIsReady(true);
    };

    setConfigs();
  }, []);
  if (isReady) {
    return children;
  }
  return null;
};
