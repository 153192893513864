import React from "react";
import "react-phone-number-input/style.css";
import { sanitize } from "dompurify";

import { styled } from "@mui/material/styles";
import PhoneInputBase from "react-phone-number-input";
export const PhoneInputStyled = styled(PhoneInputBase)(() => ({
    height: "42px",
    backgroundColor: "#F6F7FB",
    padding: "9px 8px",
    borderRadius: "4px",
    boxSizing: "border-box",
    boxShadow: "inset 0 0 0 1px transparent",
    "&:hover": {
      boxShadow: "inset 0 0 0 1px #3BB8C1",
    },
    "&:focus-within": {
      boxShadow: "inset 0 0 0 2px #3BB8C1",
    },
    ".PhoneInputInput": {
      color: "#3a4451",
      fontSize: "14px",
      "&:focus": {
        outline: "none",
      },
      "&:hover": {
        outline: "none",
      },
    },
    
  }));
  

export const InputForPhone = ({ name, value, setValue, error, disabled }) => {
  const defaultCountry = "US";

  return (
    <PhoneInputStyled
      disabled={disabled}
      error={error}
      name={name}
      value={sanitize(value)}
      country={defaultCountry}
      international={false}
      defaultCountry={defaultCountry}
      onChange={(value) => setValue(value || "")}
      countryOptionsOrder={["US", "CA", "..."]}
    />
  );
};

